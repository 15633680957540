/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `MISSING_INFORMATION` - ข้อมูลไม่ครบ * `PENDING` - รอตรวจสอบข้อมูล * `APPROVED` - อนุมัติแล้ว * `REJECTED` - ไม่อนุมัติ * `CLOSED` - ปิดถาวร
 */
export type GeneralTradeStatusEnum = 'MISSING_INFORMATION' | 'PENDING' | 'APPROVED' | 'REJECTED' | 'CLOSED'

export const GeneralTradeStatusEnum = {
  MissingInformation: 'MISSING_INFORMATION' as GeneralTradeStatusEnum,
  Pending: 'PENDING' as GeneralTradeStatusEnum,
  Approved: 'APPROVED' as GeneralTradeStatusEnum,
  Rejected: 'REJECTED' as GeneralTradeStatusEnum,
  Closed: 'CLOSED' as GeneralTradeStatusEnum,
}
